import { useEffect, useState } from "react";
import axios from "../../axios";
import "./Cleaners.scss";

const Cleaners = () => {
  const [cleaners, setCleaners] = useState([]);
  const [modalImage, setModalImage] = useState(null); // Состояние для модального окна

  useEffect(() => {
    axios
      .get("/api/users", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(response => {
        if (Array.isArray(response.data)) {
          setCleaners(response.data);
        } else {
          console.error("Ошибка: данные клинеров должны быть массивом");
          setCleaners([]); // Подстраховка, если сервер вернул не массив
        }
      })
      .catch(error => {
        console.log("Ошибка загрузки данных:", error);
        setCleaners([]); // Избегаем ошибки при `map()`
      });
  }, []);

  // Функция для конвертации `Buffer` в `Base64`
  const bufferToBase64 = buffer => {
    if (!buffer || !Array.isArray(buffer)) return null; // Проверяем, что данные есть
    const binary = buffer.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    );
    return btoa(binary);
  };

  // Открыть модальное окно
  const openModal = imageSrc => {
    setModalImage(imageSrc);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="cleaners-page">
      <h2 className="page__title">Заявки на регистрацию клинеров</h2>
      {Array.isArray(cleaners) && cleaners.length > 0 ? (
        cleaners
          .slice()
          .reverse()
          .map((cleaner, index) => (
            <div className="cleaner-card" key={index}>
              <div className="cleaner-card__info">
                <h3>Заявка на регистрацию клинера № {index + 1}</h3>
                <p>
                  <strong>Имя:</strong> {cleaner.name || "Не указано"}
                </p>
                <p>
                  <strong>Фамилия:</strong> {cleaner.surname || "Не указано"}
                </p>
                <p>
                  <strong>Отчество:</strong>{" "}
                  {cleaner.patronymic || "Не указано"}
                </p>
                <p>
                  <strong>Пол:</strong> {cleaner.gender || "Не указано"}
                </p>
                <p>
                  <strong>Возраст:</strong> {cleaner.age || "Не указано"}
                </p>
                <p>
                  <strong>Телефон:</strong> {cleaner.phone || "?"}
                </p>
                <p>
                  <strong>Email:</strong> {cleaner.email || "?"}
                </p>
                <p>
                  <strong>ИНН:</strong> {cleaner.inn || "Не указано"}
                </p>
                <p>
                  <strong>СНИЛС:</strong> {cleaner.snils || "Не указано"}
                </p>
                <p>
                  <strong>Адрес регистрации:</strong>{" "}
                  {cleaner.addressOfRegistration || "Не указано"}
                </p>
                <p>
                  <strong>Адрес проживания:</strong>{" "}
                  {cleaner.addressOfResidence || "Не указано"}
                </p>
              </div>

              <div className="cleaner-card__passport">
                <h4>Паспортные данные</h4>
                <p>
                  <strong>Серия и номер:</strong>{" "}
                  {cleaner.passport || "Не указано"}
                </p>
                <p>
                  <strong>Дата выдачи:</strong>{" "}
                  {cleaner.dateIssue || "Не указано"}
                </p>
                <p>
                  <strong>Кем выдан:</strong>{" "}
                  {cleaner.whomIssued || "Не указано"}
                </p>
                <p>
                  <strong>Код подразделения:</strong>{" "}
                  {cleaner.unitCode || "Не указано"}
                </p>
                <div className="passport-images">
                  {cleaner.passportScan && cleaner.passportScan.data ? (
                    <img
                      src={`data:${
                        cleaner.passportScan.contentType
                      };base64,${bufferToBase64(
                        cleaner.passportScan.data.data
                      )}`}
                      alt="Фото паспорта (страница 1)"
                      onClick={() =>
                        openModal(
                          `data:${
                            cleaner.passportScan.contentType
                          };base64,${bufferToBase64(
                            cleaner.passportScan.data.data
                          )}`
                        )
                      }
                    />
                  ) : (
                    <p>Фото паспорта (страница 1) отсутствует</p>
                  )}
                  {cleaner.passportScan2 && cleaner.passportScan2.data ? (
                    <img
                      src={`data:${
                        cleaner.passportScan2.contentType
                      };base64,${bufferToBase64(
                        cleaner.passportScan2.data.data
                      )}`}
                      alt="Фото паспорта (страница 2)"
                      onClick={() =>
                        openModal(
                          `data:${
                            cleaner.passportScan2.contentType
                          };base64,${bufferToBase64(
                            cleaner.passportScan2.data.data
                          )}`
                        )
                      }
                    />
                  ) : (
                    <p>Фото паспорта (страница 2) отсутствует</p>
                  )}
                </div>
              </div>

              <div className="cleaner-card__actions">
                <button className="btn btn--percentage">%</button>
                <button className="btn btn--salary">Оклад</button>
                <button className="btn btn--accept">Принять на работу</button>
                <button className="btn btn--delete">Удалить заявку</button>
              </div>
            </div>
          ))
      ) : (
        <p>Нет доступных заявок</p>
      )}

      {/* Модальное окно */}
      {modalImage && (
        <div className="modal">
          <div className="modal-content">
            <img src={modalImage} alt="Просмотр паспорта" />
            <button className="modal-close" onClick={closeModal}>
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cleaners;
